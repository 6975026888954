<template>
  <section class="p-index">
    <h2 class="p-index__heading text-4 gamma:text-5">Latest Articles</h2>

    <lazy-hydrate when-idle>
      <blog-post-preview-list :articles="articles" />
    </lazy-hydrate>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';

import BlogPostPreviewList from '~/components/BlogPostPreviewList.vue';
import { SET_INDEX, FETCH_INDEX } from '~/store/index';
import { SITE_TITLE } from '~/assets/js/constants';

let indexHead;

if (process.server) {
  indexHead = require('~/utilities/head').indexHead;
}

export default {
  components: {
    LazyHydrate,
    BlogPostPreviewList,
  },

  computed: {
    ...mapGetters(['articles']),
  },

  async fetch({ payload, store: { dispatch } }) {
    if (payload) {
      dispatch(SET_INDEX, payload);

      return;
    }

    await dispatch(FETCH_INDEX);
  },

  head() {
    if (process.server) {
      return indexHead();
    }

    const url = process.env.SITE_URL;

    return {
      title: SITE_TITLE,

      link: [{ hid: 'canonical', rel: 'canonical', href: url }],
    };
  },
};
</script>

<style lang="postcss">
.p-index {
  margin: var(--spacing-4) 0;
}

.p-index__heading {
  margin: 0 0 var(--spacing-8);
}
</style>
