<template>
  <ul class="c-blog-post-list">
    <li
      v-for="{ id, slug, url, title, publishDate, status, index } in articles"
      :key="id"
      class="c-blog-post-list__item"
    >
      <blog-post-preview
        v-bind="{ slug, url, title, publishDate, status, index }"
      />
    </li>
  </ul>
</template>

<script>
import BlogPostPreview from '~/components/BlogPostPreview.vue';

export default {
  components: {
    BlogPostPreview,
  },

  props: {
    articles: {
      type: Array,

      default: () => [],
    },
  },
};
</script>

<style lang="postcss">
.c-blog-post-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.c-blog-post-list__item {
  & + .c-blog-post-list__item {
    margin-top: var(--spacing-6);
  }
}
</style>
