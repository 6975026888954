var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"c-blog-post-list"},_vm._l((_vm.articles),function(ref){
var id = ref.id;
var slug = ref.slug;
var url = ref.url;
var title = ref.title;
var publishDate = ref.publishDate;
var status = ref.status;
var index = ref.index;
return _c('li',{key:id,staticClass:"c-blog-post-list__item"},[_c('blog-post-preview',_vm._b({},'blog-post-preview',{ slug: slug, url: url, title: title, publishDate: publishDate, status: status, index: index },false))],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }