<template>
  <article
    class="c-blog-post-preview h-entry"
    :class="{
      'is-draft': status === 'draft',
      'is-secret': !index,
    }"
    :data-qa="isHydrated ? 'blog-post-preview' : null"
  >
    <header class="c-blog-post-preview__header relative">
      <div
        v-if="isDevelopment && slug"
        class="absolute top-1/2 -translate-y-1/2 left-full hidden gamma:flex"
      >
        <nuxt-link
          :to="`/open-graph-image/${slug}/`"
          class="c-blog-post-preview__og-image-link p-2 ml-3 leading-none no-underline"
          data-qa="blog-post-preview-image-link"
        />

        <nuxt-link
          :to="`/dev-image/${slug}/`"
          class="c-blog-post-preview__dev-image-link p-2 ml-2 leading-none no-underline"
          data-qa="blog-post-preview-image-link"
        />
      </div>

      <h3 class="c-blog-post-preview__title s-blog-post-preview-title p-name">
        <viewsource-link
          :to="url"
          :types="['underlined']"
          class="c-blog-post-preview__link u-url"
          v-html="title.html"
        />
      </h3>

      <span class="c-blog-post-preview__separator" />

      <time
        :datetime="publishDate.iso8601"
        class="c-blog-post-preview__publish-date dt-published text-0"
      >
        {{ publishDate.formatted }}
      </time>
    </header>
  </article>
</template>

<script>
import ViewsourceLink from '~/components/ViewsourceLink.vue';

export default {
  components: {
    ViewsourceLink,
  },

  props: {
    slug: {
      type: String,
    },

    url: {
      type: String,

      required: true,
    },

    title: {
      type: Object,

      required: true,
    },

    publishDate: {
      type: Object,

      required: true,
    },

    status: {
      type: String,

      default: 'published',
    },

    index: {
      type: Boolean,

      default: true,
    },
  },

  data() {
    return {
      isDevelopment: process.env.SITE_ENV === 'development',
      isHydrated: false,
    };
  },

  mounted() {
    this.isHydrated = true;
  },
};
</script>

<style lang="postcss">
@import '@/assets/css/scopes/scopes.blog-post-preview-title.css';

.c-blog-post-preview__header {
  display: flex;
  flex-flow: row wrap;

  @media (--gamma) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}

.c-blog-post-preview__publish-date {
  font-family: var(--system-monospace);

  @media (--gamma) {
    flex: 0 0 auto;
  }
}

.c-blog-post-preview__separator {
  flex: 1 1 100%;
  margin: var(--spacing-2) 0;

  @media (--gamma) {
    flex: 1 0 1rem;
    position: relative;
    margin: 0 var(--spacing-3);
    height: 0;
    border-bottom: 1px dotted var(--color-ghost);
  }
}

.c-blog-post-preview__title {
  margin: 0;
  font-size: inherit;
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-title);

  @media (--gamma) {
    flex: 0 0 auto;
  }

  @nest .is-draft &, .is-secret & {
    position: relative;

    &::before {
      margin: 0 var(--spacing-2) 0 0;
      padding: var(--spacing-1) var(--spacing-2);
      font-weight: var(--font-weight-regular);
      text-decoration: none;

      @media (--gamma) {
        position: absolute;
        right: 100%;
        margin: 0 var(--spacing-3) 0 0;
      }
    }
  }

  @nest .is-draft & {
    &::before {
      content: 'draft';
      color: var(--color-white);
      background: var(--color-internet-blue);

      @media (prefers-color-scheme: dark) {
        color: var(--color-ghost);
        background: var(--color-ship-gray);
      }

      @nest .t-light & {
        color: var(--color-white);
        background: var(--color-internet-blue);
      }

      @nest .t-dark & {
        color: var(--color-ghost);
        background: var(--color-ship-gray);
      }
    }
  }

  @nest .is-secret & {
    &::before {
      content: '\1F512';
      padding-right: 0;
    }
  }
}

.c-blog-post-preview__og-image-link,
.c-blog-post-preview__dev-image-link {
  &::before {
    content: '\1F304';
    font-size: var(--font-size-4);
    line-height: 1;
  }
}

.c-blog-post-preview__dev-image-link {
  &::before {
    content: '\1F4BB';
  }
}
</style>
